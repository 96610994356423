import type { SanityClient } from '@sanity/client'
import { AsyncWalkBuilder, deepCopy } from 'walkjs'

export async function resolveDeeplyNestedSanityRefs<TData = any> (data: object, client: SanityClient, mainQuery: string): Promise<Awaited<TData>> {
  const clonedData = deepCopy(data) as any
  await replaceReferences(clonedData, client, mainQuery)
  return clonedData
}
/**
 * This function will mutate reference-objects:
 * The keys of a reference-object will be deleted and the keys of the reference-
 * document will be added.
 * eg:
 * { _type: 'reference', _ref: 'abc' }
 * becomes:
 * { _type: 'document', _id: 'abc', ...allOtherDocumentProps }
 */
export async function replaceReferences (input: unknown, client: SanityClient, mainQuery: string, resolvedIds: string[] = []) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x: any) => x.val?._type === 'reference')
    .withSimpleCallback(async (node: any) => {
      const refId = node.val._ref

      if (typeof refId !== 'string') { throw new TypeError('node.val._ref is not set') }

      if (resolvedIds.includes(refId)) {
        const ids = `[${resolvedIds.concat(refId).join(',')}]`
        throw new Error(`Ran into an infinite loop of references, please investigate the following sanity document order: ${ids}`)
      }

      const doc = await client.fetch(`*[_id == '${refId}']{...}[0]{
        ...content[0] {
          ${mainQuery}
        }
      }`)

      // recursively replace references
      await replaceReferences(doc, client, mainQuery, resolvedIds.concat(refId))

      /**
       * Here we'll mutate the original reference object by clearing the
       * existing keys and adding all keys of the reference itself.
       */
      Object.keys(node.val).forEach(key => delete node.val[key])
      Object.keys(doc).forEach(key => (node.val[key] = doc[key]))
    })
    .walk(input)
}
